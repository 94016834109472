import React from "react";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";

type Props = {
  title?: string;
  description?: string;
  keywords?: string;
  author?: string;
  children?: React.ReactNode;
};

const SEO = ({ title, description, keywords, author, children }: Props) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    keywords: defaultKeywords,
    author: defaultAuthor,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    keywords: keywords || defaultKeywords,
    author: author || defaultAuthor,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />
      <meta name="author" content={seo.author} />
      {children}
    </>
  );
};

export default SEO;
