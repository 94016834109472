import * as React from "react";
import * as S from "../styles/chapter-1-eiffel-shard.styles";
import { graphql } from "gatsby";
import type { HeadFC } from "gatsby";
import { EiffelShardQuery } from "../../graphql-types";
import { Howl } from "howler";
import SEO from "../components/SEO/SEO";

interface Props {
  data: EiffelShardQuery;
}

const EiffelShard: React.FC<Props> = ({ data }: Props) => {
  const [img0, setImg0] = React.useState(false);
  const [img1, setImg1] = React.useState(false);
  const [img2, setImg2] = React.useState(false);
  const [img3, setImg3] = React.useState(false);
  const [img4, setImg4] = React.useState(false);
  const [img5, setImg5] = React.useState(false);
  const [img6, setImg6] = React.useState(false);
  const [img7, setImg7] = React.useState(false);
  const [img8, setImg8] = React.useState(false);
  const [img9, setImg9] = React.useState(false);
  const [img10, setImg10] = React.useState(false);
  const [img11, setImg11] = React.useState(false);
  const [img12, setImg12] = React.useState(false);
  const [img13, setImg13] = React.useState(false);
  const [img14, setImg14] = React.useState(false);
  const [img15, setImg15] = React.useState(false);
  const [img16, setImg16] = React.useState(false);
  const [img17, setImg17] = React.useState(false);
  const [img18, setImg18] = React.useState(false);
  const [img19, setImg19] = React.useState(false);
  const [img20, setImg20] = React.useState(false);
  const [img21, setImg21] = React.useState(false);
  const [img22, setImg22] = React.useState(false);
  const [img23, setImg23] = React.useState(false);

  const audio = data.audio.nodes;
  const pause = data.pause.nodes;
  const play = data.play.nodes;

  const sound0 = React.useRef(
    new Howl({
      src: [audio[0].url ?? ""],
      sprite: {
        main: [0, 2000, true],
      },
    })
  );

  const sound1 = React.useRef(
    new Howl({
      src: [audio[1].url ?? ""],
      sprite: {
        main: [0, 2000, true],
      },
    })
  );
  const sound2 = React.useRef(
    new Howl({
      src: [audio[2].url ?? ""],
      sprite: {
        main: [0, 7414, true],
      },
    })
  );

  const sound3 = React.useRef(
    new Howl({
      src: [audio[3].url ?? ""],
      sprite: {
        main: [0, 12015, true],
      },
    })
  );

  const sound4 = React.useRef(
    new Howl({
      src: [audio[4].url ?? ""],
      sprite: {
        main: [0, 12015, true],
      },
    })
  );

  const sound5 = React.useRef(
    new Howl({
      src: [audio[5].url ?? ""],
      sprite: {
        main: [0, 7818, true],
      },
    })
  );

  const sound6 = React.useRef(
    new Howl({
      src: [audio[6].url ?? ""],
      sprite: {
        main: [0, 12800, true],
      },
    })
  );

  const sound7 = React.useRef(
    new Howl({
      src: [audio[7].url ?? ""],
      sprite: {
        main: [0, 2467, true],
      },
    })
  );

  const sound8 = React.useRef(
    new Howl({
      src: [audio[8].url ?? ""],
      sprite: {
        main: [0, 23438, true],
      },
    })
  );

  const sound9 = React.useRef(
    new Howl({
      src: [audio[9].url ?? ""],
      sprite: {
        main: [0, 5500, true],
      },
    })
  );

  const sound10 = React.useRef(
    new Howl({
      src: [audio[10].url ?? ""],
      sprite: {
        main: [0, 13755, true],
      },
    })
  );

  const sound11 = React.useRef(
    new Howl({
      src: [audio[11].url ?? ""],
      sprite: {
        main: [0, 5227, true],
      },
    })
  );

  const sound12 = React.useRef(
    new Howl({
      src: [audio[12].url ?? ""],
      sprite: {
        main: [0, 30750, true],
      },
    })
  );

  const sound13 = React.useRef(
    new Howl({
      src: [audio[13].url ?? ""],
      sprite: {
        main: [0, 4280, true],
      },
    })
  );

  const sound14 = React.useRef(
    new Howl({
      src: [audio[14].url ?? ""],
      sprite: {
        main: [0, 8511, true],
      },
    })
  );

  const sound15 = React.useRef(
    new Howl({
      src: [audio[15].url ?? ""],
      sprite: {
        main: [0, 2500, true],
      },
    })
  );

  const sound16 = React.useRef(
    new Howl({
      src: [audio[16].url ?? ""],
      sprite: {
        main: [0, 9583, true],
      },
    })
  );

  const sound17 = React.useRef(
    new Howl({
      src: [audio[17].url ?? ""],
      sprite: {
        main: [0, 3598, true],
      },
    })
  );

  const sound18 = React.useRef(
    new Howl({
      src: [audio[18].url ?? ""],
      sprite: {
        main: [0, 3902, true],
      },
    })
  );

  const sound19 = React.useRef(
    new Howl({
      src: [audio[19].url ?? ""],
      sprite: {
        main: [0, 31867, true],
      },
    })
  );

  const sound20 = React.useRef(
    new Howl({
      src: [audio[20].url ?? ""],
      sprite: {
        main: [0, 17496, true],
      },
    })
  );

  const sound21 = React.useRef(
    new Howl({
      src: [audio[21].url ?? ""],
      sprite: {
        main: [0, 8700, true],
      },
    })
  );

  const sound22 = React.useRef(
    new Howl({
      src: [audio[22].url ?? ""],
      sprite: {
        main: [0, 11133, true],
      },
    })
  );

  const sound23 = React.useRef(
    new Howl({
      src: [audio[23].url ?? ""],
      sprite: {
        main: [0, 12800, true],
      },
    })
  );

  const toggle = (img: boolean, setImg: Function, sound: Howl) => {
    setImg(!img);
    if (!img) {
      sound.play("main");
      sound.fade(0.0, 1.0, 200);
    } else {
      sound.fade(1.0, 0.0, 200);
      setTimeout(() => {
        sound.stop();
      }, 205);
    }
  };

  return (
    <S.EiffelShard>
      <S.Image
        onClick={() => toggle(img0, setImg0, sound0.current)}
        src={img0 ? `${play[0].url}` : `${pause[0].url}`}
        alt={img0 ? `${play[0].title}` : `${pause[0].title}`}
      />
      <S.Image
        onClick={() => toggle(img1, setImg1, sound1.current)}
        src={img1 ? `${play[1].url}` : `${pause[1].url}`}
        alt={img1 ? `${play[1].title}` : `${pause[1].title}`}
      />
      <S.Image
        onClick={() => toggle(img2, setImg2, sound2.current)}
        src={img2 ? `${play[2].url}` : `${pause[2].url}`}
        alt={img2 ? `${play[2].title}` : `${pause[2].title}`}
      />
      <S.Image
        onClick={() => toggle(img3, setImg3, sound3.current)}
        src={img3 ? `${play[3].url}` : `${pause[3].url}`}
        alt={img3 ? `${play[3].title}` : `${pause[3].title}`}
      />
      <S.Image
        onClick={() => toggle(img4, setImg4, sound4.current)}
        src={img4 ? `${play[4].url}` : `${pause[4].url}`}
        alt={img4 ? `${play[4].title}` : `${pause[4].title}`}
      />
      <S.Image
        onClick={() => toggle(img5, setImg5, sound5.current)}
        src={img5 ? `${play[5].url}` : `${pause[5].url}`}
        alt={img5 ? `${play[5].title}` : `${pause[5].title}`}
      />
      <S.Image
        onClick={() => toggle(img6, setImg6, sound6.current)}
        src={img6 ? `${play[6].url}` : `${pause[6].url}`}
        alt={img6 ? `${play[6].title}` : `${pause[6].title}`}
      />
      <S.Image
        onClick={() => toggle(img7, setImg7, sound7.current)}
        src={img7 ? `${play[7].url}` : `${pause[7].url}`}
        alt={img7 ? `${play[7].title}` : `${pause[7].title}`}
      />
      <S.Image
        onClick={() => toggle(img8, setImg8, sound8.current)}
        src={img8 ? `${play[8].url}` : `${pause[8].url}`}
        alt={img8 ? `${play[8].title}` : `${pause[8].title}`}
      />
      <S.Image
        onClick={() => toggle(img9, setImg9, sound9.current)}
        src={img9 ? `${play[9].url}` : `${pause[9].url}`}
        alt={img9 ? `${play[9].title}` : `${pause[9].title}`}
      />
      <S.Image
        onClick={() => toggle(img10, setImg10, sound10.current)}
        src={img10 ? `${play[10].url}` : `${pause[10].url}`}
        alt={img10 ? `${play[10].title}` : `${pause[10].title}`}
      />
      <S.Image
        onClick={() => toggle(img11, setImg11, sound11.current)}
        src={img11 ? `${play[11].url}` : `${pause[11].url}`}
        alt={img11 ? `${play[11].title}` : `${pause[11].title}`}
      />
      <S.Image
        onClick={() => toggle(img12, setImg12, sound12.current)}
        src={img12 ? `${play[12].url}` : `${pause[12].url}`}
        alt={img12 ? `${play[12].title}` : `${pause[12].title}`}
      />
      <S.Image
        onClick={() => toggle(img13, setImg13, sound13.current)}
        src={img13 ? `${play[13].url}` : `${pause[13].url}`}
        alt={img13 ? `${play[13].title}` : `${pause[13].title}`}
      />
      <S.Image
        onClick={() => toggle(img14, setImg14, sound14.current)}
        src={img14 ? `${play[14].url}` : `${pause[14].url}`}
        alt={img14 ? `${play[14].title}` : `${pause[14].title}`}
      />
      <S.Image
        onClick={() => toggle(img15, setImg15, sound15.current)}
        src={img15 ? `${play[15].url}` : `${pause[15].url}`}
        alt={img15 ? `${play[15].title}` : `${pause[15].title}`}
      />
      <S.Image
        onClick={() => toggle(img16, setImg16, sound16.current)}
        src={img16 ? `${play[16].url}` : `${pause[16].url}`}
        alt={img16 ? `${play[16].title}` : `${pause[16].title}`}
      />
      <S.Image
        onClick={() => toggle(img17, setImg17, sound17.current)}
        src={img17 ? `${play[17].url}` : `${pause[17].url}`}
        alt={img17 ? `${play[17].title}` : `${pause[17].title}`}
      />
      <S.Image
        onClick={() => toggle(img18, setImg18, sound18.current)}
        src={img18 ? `${play[18].url}` : `${pause[18].url}`}
        alt={img18 ? `${play[18].title}` : `${pause[18].title}`}
      />
      <S.Image
        onClick={() => toggle(img19, setImg19, sound19.current)}
        src={img19 ? `${play[19].url}` : `${pause[19].url}`}
        alt={img19 ? `${play[19].title}` : `${pause[19].title}`}
      />
      <S.Image
        onClick={() => toggle(img20, setImg20, sound20.current)}
        src={img20 ? `${play[20].url}` : `${pause[20].url}`}
        alt={img20 ? `${play[20].title}` : `${pause[20].title}`}
      />
      <S.Image
        onClick={() => toggle(img21, setImg21, sound21.current)}
        src={img21 ? `${play[21].url}` : `${pause[21].url}`}
        alt={img21 ? `${play[21].title}` : `${pause[21].title}`}
      />
      <S.Image
        onClick={() => toggle(img22, setImg22, sound22.current)}
        src={img22 ? `${play[22].url}` : `${pause[22].url}`}
        alt={img22 ? `${play[22].title}` : `${pause[22].title}`}
      />
      <S.Image
        onClick={() => toggle(img23, setImg23, sound23.current)}
        src={img23 ? `${play[23].url}` : `${pause[23].url}`}
        alt={img23 ? `${play[23].title}` : `${pause[23].title}`}
      />
    </S.EiffelShard>
  );
};

export default EiffelShard;

export const Head: HeadFC = () => (
  <SEO title="Ormside - Chapter 1: Eiffel Shard" />
);

export const query = graphql`
  query EiffelShard {
    play: allContentfulAsset(
      sort: { fields: updatedAt, order: ASC }
      filter: {
        title: {
          in: [
            "eiffel shard play 0"
            "eiffel shard play 1"
            "eiffel shard play 2"
            "eiffel shard play 3"
            "eiffel shard play 4"
            "eiffel shard play 5"
            "eiffel shard play 6"
            "eiffel shard play 7"
            "eiffel shard play 8"
            "eiffel shard play 9"
            "eiffel shard play 10"
            "eiffel shard play 11"
            "eiffel shard play 12"
            "eiffel shard play 13"
            "eiffel shard play 14"
            "eiffel shard play 15"
            "eiffel shard play 16"
            "eiffel shard play 17"
            "eiffel shard play 18"
            "eiffel shard play 19"
            "eiffel shard play 20"
            "eiffel shard play 21"
            "eiffel shard play 22"
            "eiffel shard play 23"
          ]
        }
      }
    ) {
      nodes {
        title
        gatsbyImageData
        url
      }
    }
    pause: allContentfulAsset(
      sort: { fields: updatedAt, order: ASC }
      filter: {
        title: {
          in: [
            "eiffel shard pause 0"
            "eiffel shard pause 1"
            "eiffel shard pause 2"
            "eiffel shard pause 3"
            "eiffel shard pause 4"
            "eiffel shard pause 5"
            "eiffel shard pause 6"
            "eiffel shard pause 7"
            "eiffel shard pause 8"
            "eiffel shard pause 9"
            "eiffel shard pause 10"
            "eiffel shard pause 11"
            "eiffel shard pause 12"
            "eiffel shard pause 13"
            "eiffel shard pause 14"
            "eiffel shard pause 15"
            "eiffel shard pause 16"
            "eiffel shard pause 17"
            "eiffel shard pause 18"
            "eiffel shard pause 19"
            "eiffel shard pause 20"
            "eiffel shard pause 21"
            "eiffel shard pause 22"
            "eiffel shard pause 23"
          ]
        }
      }
    ) {
      nodes {
        title
        gatsbyImageData
        url
      }
    }
    audio: allContentfulAsset(
      sort: { fields: updatedAt, order: ASC }
      filter: {
        title: {
          in: [
            "eiffel shard 0"
            "eiffel shard 1"
            "eiffel shard 2"
            "eiffel shard 3"
            "eiffel shard 4"
            "eiffel shard 5"
            "eiffel shard 6"
            "eiffel shard 7"
            "eiffel shard 8"
            "eiffel shard 9"
            "eiffel shard 10"
            "eiffel shard 11"
            "eiffel shard 12"
            "eiffel shard 13"
            "eiffel shard 14"
            "eiffel shard 15"
            "eiffel shard 16"
            "eiffel shard 17"
            "eiffel shard 18"
            "eiffel shard 19"
            "eiffel shard 20"
            "eiffel shard 21"
            "eiffel shard 22"
            "eiffel shard 23"
          ]
        }
      }
    ) {
      nodes {
        title
        url
      }
    }
  }
`;
