import styled from "styled-components";

export const EiffelShard = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
`;

export const Image = styled.img`
  width: 50vw;
  margin: 0;
  padding: 0;

  &:hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
`;
